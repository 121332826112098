import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import CategoryPageBody from './CategoryPageBody'
import parseQueryItems from '../data/parseQueryItems'

const GatsbyCategoryPage = ({ data, pageContext: { title, name } }) => (
  <StandardPage>
    <SEO title={title} />
    <CategoryPageBody
      items={parseQueryItems(data)}
      category={{ title, name }}
    />
  </StandardPage>
)

export default GatsbyCategoryPage
