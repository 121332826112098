import React from 'react'
import useFeaturedBooks from '../data/useFeaturedBooks'
import ProductList from './ProductList'

const FeaturedBookList = props => {
  const items = useFeaturedBooks()

  return <ProductList title="Books" items={items} {...props} />
}

export default FeaturedBookList
