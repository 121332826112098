import { useStaticQuery, graphql } from 'gatsby'
import parseQueryItems from './parseQueryItems'

const useAllBooks = () =>
  parseQueryItems(
    useStaticQuery(graphql`
      query {
        ...AllBookListFragment
      }
    `),
  )

export default useAllBooks
