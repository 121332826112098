import { useStaticQuery, graphql } from 'gatsby'
import parseQueryItems from './parseQueryItems'

const useFeaturedBooks = () =>
  parseQueryItems(
    useStaticQuery(graphql`
      query {
        ...FeaturedBookListFragment
      }
    `),
  )

export default useFeaturedBooks
