import React from 'react'
import Dotdotdot from '@foundation-lib/react-dotdotdot'
import ProductList, {
  Image,
  ImageLink,
  Container,
  Details,
  Title,
  Description,
  Link,
} from 'src/features/products/components/ProductList'

export const ArtworkTile = ({ img, title, href, description, excerpt }) => (
  <Container>
    <ImageLink to={href}>
      <Image src={img} alt={title} />
    </ImageLink>
    <Details>
      <Link to={href}>
        <Title>{title}</Title>
      </Link>
      <Description>
        <Dotdotdot clamp={3}>{description || excerpt}</Dotdotdot>
      </Description>
    </Details>
  </Container>
)

const ArtworkList = ({ items }) => (
  <ProductList
    title="Original artwork"
    ItemComponent={ArtworkTile}
    items={items}
  />
)

export default ArtworkList
