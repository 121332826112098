import React from 'react'
// import { ResponsiveTiledList, ListTitle } from 'src/features/lists'
// import { Section } from 'src/features/layout'
// import useAllProducts from '../data/useAllProducts'
// import useCategories from '../data/useCategories'
import ProductList from './ProductList'
import useAllBooks from '../data/useAllBooks'
import useAllArtwork from '../data/useAllArtwork'
import ArtworkList from './ArtworkList'

const ProductsPageBody = () => {
  // const items = useAllProducts()
  const books = useAllBooks()
  const artworkItems = useAllArtwork()
  // const categories = useCategories()

  // const getItemsForCategory = ({ name }) =>
  //   items.filter(({ category }) => category === name)

  return (
    <>
      <h1>Products</h1>
      <ProductList title="Books" items={books} />
      <br />
      <ArtworkList items={artworkItems} />

      {/* {categories
        .sort((a, b) => a.order - b.order)
        .filter(({ name }) => name !== 'books')
        .map(({ title, name }) => (
          <Section>
            <ListTitle>{title}</ListTitle>
            <ResponsiveTiledList items={getItemsForCategory({ name })} />
          </Section>
        ))} */}
    </>
  )
}

export default ProductsPageBody
