import React from 'react'
import { TiledList, TiledListItem } from '../../lists'

const CategoryPageBody = ({ items, category: { title } }) => {
  return (
    <>
      <h1>{title}</h1>

      <TiledList>
        {items.map(item => (
          <TiledListItem {...item} />
        ))}
      </TiledList>
    </>
  )
}

export default CategoryPageBody
