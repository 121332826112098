const parseQueryItems = data => {
  const items = data?.allMarkdownRemark?.edges || []

  return items.map(({ node: product }) => {
    const { frontmatter, excerpt, fields } = product
    const {
      featuredimage,
      title,
      name,
      category,
      order,
      description,
      color,
      options,
    } = frontmatter
    const { slug: href } = fields
    const img = featuredimage?.childImageSharp?.fixed?.src
    return {
      excerpt,
      title,
      description,
      href,
      img,
      name,
      category,
      order: order || 100,
      color,
      options,
    }
  })
}

export default parseQueryItems
