import React from 'react'
import useFeaturedArtwork from '../data/useFeaturedArtwork'
import ArtworkList from './ArtworkList'

const FeaturedArtworkList = () => {
  const items = useFeaturedArtwork()

  return <ArtworkList items={items} />
}

export default FeaturedArtworkList
