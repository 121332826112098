import { useStaticQuery, graphql } from 'gatsby'
import parseQueryItems from './parseQueryItems'

const useAllArtwork = () =>
  parseQueryItems(
    useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { in: ["product"] }
              category: { eq: "artwork" }
            }
          }
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                ...ItemFrontmatterFieldsFragment
                ...SquareProductImageFragment
              }
            }
          }
        }
      }
    `),
  )

export default useAllArtwork
