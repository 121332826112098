import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import ProductsPageBody from './ProductsPageBody'

const GatsbyProductsPage = () => (
  <StandardPage>
    <SEO title="Products" />
    <ProductsPageBody />
  </StandardPage>
)

export default GatsbyProductsPage
